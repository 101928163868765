<template>
    <div>
        <div v-if="!isShow" class="container-main">
            <div class="container_bg">
                <div class="title">
                    <div class="login">
                        <img src="../../assets/images/logo.png" alt="" class="logo_img">
                        <!-- <span> Pacific Foods</span> -->
                    </div>
                    <div class="back" @click="backMap">
                        <img src="../../assets/images/quit.png" alt="">
                    </div>
                </div>
                <div class="main">
                    <div class="main_center">
                        <el-row :gutter="20">
                            <el-col :span="6">
                                <div class="tap_main" @click="tapActive('TRADING')">
                                <span class="tap_text">Existing Customers</span>
                                <div class="tap_line greenline" v-show="activeName == 'TRADING'"></div>
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div class="tap_main" @click="tapActive('NODEAL')">
                                    <span class="tap_text">Waiting Customers</span>
                                    <div class="tap_line redline" v-show="activeName == 'NODEAL'"></div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-table
                            :data="businessData.slice((dataLimit.page-1)*dataLimit.limit,dataLimit.page*dataLimit.limit)"
                            :header-cell-style="{background:'#143778',color:'#71CDF9',fontSize: '0.2375rem',border:'0px'}"
                            :row-style="{height:'.75rem'}" 
                            style="width: 100%;"
                            @row-click="Rowclick"
                            size="mini">
                            <template slot="empty">
                                <span style="color: #969799;">No data</span>
                            </template>
                            <!-- <el-table-column
                            prop="shopCode"
                            label="客户编号"
                            show-overflow-tooltip
                            align="center">
                            </el-table-column> -->
                            <el-table-column
                            prop="name"
                            label="Name"
                            show-overflow-tooltip
                            align="center">
                            </el-table-column>
                            <el-table-column
                            prop="contact"
                            label="Contact"
                            show-overflow-tooltip
                            align="center">
                            </el-table-column>
                            <el-table-column
                            prop="telephone"
                            label="Phone"
                            show-overflow-tooltip
                            align="center">
                            </el-table-column>
                            <el-table-column
                            prop="address"
                            label="Address"
                            show-overflow-tooltip
                            align="center">
                            </el-table-column>
                            <el-table-column
                            prop="postCode"
                            label="Postcode"
                            show-overflow-tooltip
                            align="center"
                            >
                            </el-table-column>
                        </el-table>
                        <div class="block" v-if="businessWeeksize >= 10">
                            <el-pagination
                                layout="prev, pager, next"
                                :pager-count="5"
                                :page-size="dataLimit.limit"
                                :current-page="dataLimit.page"
                                @current-change="week_current_change"
                                :total="businessWeeksize">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isShow">
            <map-total></map-total>
        </div>
    </div>
    
</template>
<script>
import {getStoreList,getShopAmountList} from '../../api/business'
import {mapState,mapMutations} from 'vuex'
import mapTotal from "@/components/map/total.vue"
export default {
    components:{
        mapTotal
    },
    data() {
        return {
            businessData:[],
            businessWeeksize:0,
            dataLimit:{
                limit:10,
                page:1
            },
            activeName:'TRADING',
            finishData:[],
            undoneData:[],
            isShow:false
        }
    },
    computed:{
        ...mapState(['mapDetailType'])
    },
    watch:{
        "mapDetailType":{
            handler: function (newValue, oldValue) {
                this.isShow = newValue;
            },
            deep: true
        }
    },
    created(){
        this.initFunction()
    },
    methods:{
        initFunction() {
            // getStoreList({areaId:''}).then(res => {
            //     this.businessData = [];
            //     this.finishData = [];
            //     this.undoneData = [];
            //     let result = [];
            //     let ary = res.data.data;
            //     ary.map(item => {
            //         var reg = RegExp(/Cosmo/);
            //         if(!item.name.match(reg)){
            //             result.push(item);   
            //         }
            //     })
            //     result.map(item => {
            //         if(item.amount*1 == 0) {
            //             this.undoneData.push(item)
            //         }else {
            //             this.finishData.push(item)
            //         }
            //     })
            //     if(this.activeName == 'TRADING') {
            //         this.businessData = this.finishData;
            //     }else {
            //         this.businessData = this.undoneData;
            //     }
            //     this.businessWeeksize = this.businessData.length;
            // })
            getShopAmountList().then(res => {
                this.businessData = [];
                this.finishData = [];
                this.undoneData = [];
                let result = [];
                let ary = res.data.data;
                ary.map(item => {
                    var reg = RegExp(/Cosmo/);
                    // if(!item.name.match(reg)){
                    //     result.push(item);   
                    // }
                    if(!item.name.match(reg) && item.longitude !=  '' && item.latitude != ''){
                        result.push(item);  
                    }
                })
                result.map(item => {
                    if(item.amount*1 == 0) {
                        this.undoneData.push(item)
                    }else {
                        this.finishData.push(item)
                    }
                })
                if(this.activeName == 'TRADING') {
                    this.businessData = this.finishData;
                }else {
                    this.businessData = this.undoneData;
                }
                this.businessWeeksize = this.businessData.length;
            })
        },
        backMap() {
            this.$emit("ParentType",'main');
            this.$emit("userType",'main');
        },
        week_current_change(page){
            this.dataLimit.page = page;
        },
        tapActive(value) {
            this.activeName = value;
            if(this.activeName == 'TRADING') {
                this.businessData = this.finishData;
            }else {
                this.businessData = this.undoneData;
            }
            this.businessWeeksize = this.businessData.length;
            this.dataLimit.page = 1;
        },
        Rowclick(item) {
            this.$store.commit('changeDetailType',true)
            this.$store.commit('setuserCode',item.shopCode)
        }
    }
}
</script>
<style scoped>
    .container-main {
        width: 100%;
        height: 100%;
        background: url('../../assets/images/analysis.png') no-repeat;
        background-size: 100% 100%;
    }
    .container_bg {
        width: 100%;
        height: 100%;
        padding: .375rem 1.5rem;
        box-sizing: border-box;
    }
    .title {
        display: flex;
        justify-content:space-between;
        align-items:center;
        font-size: .4rem;
        font-weight: 500;
        color: #fff;
        margin-bottom: .375rem;
    }
    /* .login img {
        width: .7375rem;
        height: .7375rem;
        vertical-align: middle;
    } */
    .back {
        width: .4625rem;
        height: .375rem;
    }
    .main {
        height: 10.6rem;
        width: 100%;
        background: url('../../assets/images/tablebg.png') no-repeat;
        background-size: 100% 100%;
    }
    .linetime {
        z-index: 10000;
        width: 50%;
        background: rgba(250, 250, 250, 0.1);
        border: none;
    }
    .main_center {
        padding: 1rem .9625rem;
        position: relative;
    }
    .tap_main {
        position: relative;
        box-sizing: border-box;
        z-index: 1;
        margin-bottom: .375rem;
    }
    .tap_text {
        font-size: .375rem;
        font-weight: 500;
        color:#fff;
        z-index: -1;
    }
    .tap_line {
        position: absolute;
        bottom:-20%;
        left: 8%;
        width: 85%;
        height: .25rem;
        border-radius: .25rem;
        z-index: -2;
    }
    .greenline {
        background: #06D28D;
    }
    .redline {
        background: #F2609F;
    }
    .block {
        position: absolute;
        bottom: 4%;
        right: 10%;
    }
    .logo_img {
        width: 2.5rem;
        height: .875rem;
        vertical-align: middle;
        text-align: left;
        display: inline-block;
    }
</style>