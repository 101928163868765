<template>
    <!-- All customer表格 -->
    <div class="add-supplier">
        <div class="showTable"  @click="closeTable"></div>
        <div class="supplier-bg"></div>
        <div class="add-supplier-bor">
            <div class="add-supplier-title">
                <div>Weekly Sales <countTo :startVal='0' :endVal='customerObj.weeklySales?customerObj.weeklySales:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo></div>
                <div>Existing Customer {{customerObj.existingCustomer}}</div>
                <div>Waiting Customer {{customerObj.waitingCustomer}}</div>
            </div>
            <div class="main_center">
                
                <div class="supplier-table"> 
                    <div class="supplier-table-head">
                        <div class="s-tab-num"></div>
                        <div class="s-tab-left">Name</div>
                        <div class="s-tab-right">Sales</div>
                    </div>
                    <div class="supplier-table-body" ref="scrollview">
                        <div class="supplier-table-li" v-for="(item,index) in tableData" :id="'anchor'+item.pageNum+item.counts" :key="index">
                            <div class="li-bor move-effect" v-if="item.address != ''">
                                <div class="s-tab-num">{{index+1}}</div>
                                <div class="s-tab-left">{{item.name.split('£')[0]}}<br/><span :title="item.address">{{item.address}}</span></div>
                                <div class="s-tab-right"> <countTo :startVal='0' :endVal='item.amount?item.amount:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo></div>
                            </div>
                            <div class="li-bor" v-else>
                                <div class="s-tab-num"></div>
                                <div class="s-tab-left"></div>
                                <div class="s-tab-right"> </div>    
                            </div>
                        </div>
                    </div>
                </div>
                

                <div class="supplier-bottom">
                    <div class="block" v-if="tableLength >= 6">
                        <el-pagination
                            layout="prev, pager, next"
                            :pager-count="5"
                            :page-size="dayLimit.limit"
                            :current-page="dayLimit.page"
                            @current-change="current_change"
                            :total="tableLength">
                        </el-pagination>
                    </div>
                    <div class="more" @click="rightShow" v-if="tableLength >= 6">
                        <div class="more_text">
                            <span>Show All</span>
                            <img src="../../assets/images/right_arrow.png" alt="">
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import countTo from 'vue-count-to';
export default {
    components: { countTo },
    props:{
        tableData: {
            type: Array, 
            required: true,
        },
        tableLength: {
            type: Number,
            default: 0,
        },
        num: {
            type: Number,
            default: 0,
        },
        customerObj:{
            type: Object,
            default:{
                existingCustomer:'',
                waitingCustomer: '',
                weeklySales:''
            }
        }
    },
    data(){
        return{
            dayLimit:{
                page: 1,
                limit:6,
                searchDate:'',
                categoryCode:"",
                all:false
            },
            dom:''
        }
    },
    mounted() {
        // 获取需要绑定的table
        this.dom = this.$refs['scrollview'];
        this.dom.addEventListener('scroll', () => {
            let scrollTop = this.dom.scrollTop;
            this.$emit("scrollFun",scrollTop,'0');
        })
    },
    methods:{
        supplierTabNum(page){
            this.dayLimit.page = page;
        },
        rightShow(){
            this.$emit("showTable");
        },
        current_change(page) {
            this.dayLimit.page = page;
            let PageId = document.querySelector('#anchor' + page +'0')
            // PageId.scrollIntoView({ behavior:"smooth"});
            PageId.parentNode.scrollTop = PageId.offsetTop-65;
        },
        showTable() {
           let dom =  document.getElementsByClassName('add-supplier')[0];
            dom.animate({top:'0.8rem',left :'0.125rem'},{duration: 1000,fill:'forwards',delay:0,easing:'linear'})
        },
        closeTable () {
            let dom =  document.getElementsByClassName('add-supplier')[0];
            dom.animate({top:'10rem',left :'-7.5rem'},{duration: 1000,fill:'forwards',delay:0,easing:'linear'})
            this.$emit('closeTable')
        }
    }
}
</script>

<style scoped>
    .showTable {
        width: 0.53rem;
        height: 1.06rem;
        line-height: 1.06rem;
        position: absolute;
        top:50%;
        right: -0.26rem;
        background: url('../../assets/images/show_table.png') no-repeat;
        background-size: 100% 100%;
        z-index: 1000004;
        cursor: pointer;
    }
    .add-supplier{
        /* background-color: #000; */
        /* background: url('../../assets/images/none_bg.png') no-repeat; */
        /* filter: brightness(50%); */
        background-size: 100% 100%;
        width: 35%;
        height: 8.6rem;
        position: absolute;
        top: 10rem;/*0.8rem;*/
        left:  -7.5rem; /* 0.125rem;*/
        z-index: 1;
    }
    .supplier-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* background-color: rgba(000, 000, 000, 0.4); */
    }
    .add-supplier-bor{
        width: 96%;
        height: 104%;
        margin: 2%;
        background: url('../../assets/images/left_top2.png') no-repeat;
        background-size: 100% 100%;
        position: relative;
        z-index: 2;
    }
    .add-supplier-title{
        font-size: .32rem;
        font-weight: 500;
        color: #fff;
        text-align: center;
        padding-top: .525rem;
        /* display: flex;
        align-items: center;
        justify-content: center; */
    }
    .supplier-title-right{
        font-size: .24rem;
        text-align: left;
        padding-left: 5%;
        font-weight: normal;
    }
    .main_center {
        /* padding: .1625rem 1.0625rem .5625rem 1.0625rem; */
        padding: 0rem 0.325rem .5625rem 0.325rem;
        text-align: center;
        margin: 0  auto;
        height: 5.45rem;
        position: relative;
    }
    /deep/ .el-table td.el-table__cell {
        border-bottom: 0;
        color: #fff;
        background-color: #091c38;
    }
    /deep/ .el-table::before {
        width: 0;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td{
        background-color: #235dc8 !important;
    }
    /deep/ .el-pagination button:disabled {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination button {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager .number{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager li.active{
        color: #409EFF !important;
    }
    /deep/ .el-date-editor .el-input__inner {
       background: rgba(250, 250, 250, 0.1);
        color: #fff;
        border: 0;
        text-align: center;
    }

    
    
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 8px !important; /*滚动条宽度*/
    height: 8px !important; /*滚动条高度*/
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
    border-radius: 0px !important; /*滚动条的背景区域的圆角*/
    background-color: rgba(2,29,87,1) !important; /*滚动条的背景颜色*/
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-corner {
    background-color: rgba(2,29,87,1) !important;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 4px !important; /*滚动条的圆角*/
    background-color: rgba(24,144,255,0.50) !important; /*滚动条的背景颜色*/
    }

    /deep/ .el-table .gutter{
        background: rgb(20, 55, 120) !important;
    }

    /* 设置滚动条的样式 */
    ::-webkit-scrollbar {
        width: 8px !important; /*滚动条宽度*/
        height: 8px !important; /*滚动条高度*/
    }
    /* 滚动槽 */
    ::-webkit-scrollbar-track {
        border-radius: 0px !important; /*滚动条的背景区域的圆角*/
        background-color: rgba(2,29,87,1) !important; /*滚动条的背景颜色*/
    }
    /* 滚动条滑块 */
    ::-webkit-scrollbar-thumb {
        border-radius: 4px !important; /*滚动条的圆角*/
        background-color: rgba(24,144,255,0.50) !important; /*滚动条的背景颜色*/
    }
    ::-webkit-scrollbar-thumb:window-inactive {
        background-color: rgba(2,29,87,1) !important;
    }



    .supplier-bottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.225rem;
        background: #071632;
    }
    .block{
        
    }
    .more {
        padding-right: 2%;
    }
    .more_text {
        display: flex;
        justify-content: space-around;
        color: #fff;
    }
    .more_text img {
        vertical-align: text-bottom;
        height:12px;
    }
    /deep/ .linetime1 .el-date-editor.el-input{
        width: 100%;
    }
    /deep/ .el-table__empty-block {
        background: #091c38;
    }
    /deep/ .el-table__body-wrapper {
        background: #091c38;
    }
    /deep/ .el-table__header-wrapper {
        background: #091c38;
    }
    /deep/ .el-pager li.btn-quicknext {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pager li.btn-quickprev{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-dropdown {
        color:#71CDF9 !important;
        font-size: 0.2375rem !important;
    }


    .supplier-table{
        width:100%;
        margin-top: 0.1875rem;
        height: 5.45rem;
    }
    .supplier-table-head{
        display: flex;
        justify-content: space-around;
        background: rgb(20, 55, 120);
        color: rgb(113, 205, 249);
        font-size: 0.2375rem;
        height: 0.825rem;
        line-height:0.825rem;
    }
    .supplier-table-body{
        height: 4.85rem;
        overflow-y: scroll;
        background: #071632;
        color: #fff;
    }
    .s-tab-left{
        width: 55%;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }
    .s-tab-right{
        width: 30%;
    }
    .s-tab-num{
        width: 15%;
    }
    .supplier-table-li{
        
    }
    .li-bor{
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        /* padding: 0.286rem 0; */
        height: 0.825rem;
        font-size: 0.16rem !important;
    }
    .move-effect:hover{
        background-color: #235dc8 !important;
    }
</style>