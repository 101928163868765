<template>
    <div class="containers">
        <div v-show="!isShow">
            <div v-show="isMap">
                <div class="title">
                    <div class="login">
                        <img src="../../assets/images/logo.png" alt="" class="logo_img">
                        <!-- <span> Pacific Foods</span> -->
                    </div>
                    <div>
                        <span> THE LOCATION INFORMATION </span>
                    </div>
                    <div class="back" @click="backMap">
                        <img src="../../assets/images/quit.png" alt="">
                    </div>
                </div>
                <div class="main">
                    <!-- <span class="mainleft"><img src="../../assets/images/left_border.png" alt=""></span> -->
                    <div id="maps" v-loading="loading"
                        element-loading-text="Loading..."
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(255, 255, 255, 0.8)">
                        <div class="select">
                            <div class="select_btn">
                                <span class="select_text" style="border-right:1px solid #dcdfe6" @click="changeMap('0')">All Customers</span><span class="select_text" style="border-right:1px solid #dcdfe6" @click="changeMap('1')">All Suppliers</span><span class="select_text" @click="changeMap('2')">All Takeaways</span>
                            </div>
                        </div>
                        <div  :class="[tableStatus ? 'show_tablle' :'hidden_table','show_table_btn']" v-show="tableStatus"  @click="showLeftTable">Table</div>
                         <!-- 搜索 -->
                        <div class="search_class" v-if="changeIndex == '2'">
                            <el-input placeholder="请输入PostCode" v-model="postcodeNum" clearable>
                                <el-button class="search_button" slot="append" @click="searchFun" icon="el-icon-search"></el-button>
                            </el-input>
                            <!-- <el-input class="search_input" v-model="postcodeNum" placeholder="请输入内容"></el-input> -->
                            <!-- <el-button class="search_button" type="primary">搜索</el-button> -->
                        </div>
                        <!-- 选择距离范围 -->
                        <div class="distance_legend" v-if="changeIndex == '2'">
                            <div :class="distanceType == '0' ? 'distance_legend_active':''" @click="distanceFun(8395.4112)">30 min</div>
                            <div :class="distanceType == '1' ? 'distance_legend_active':''" @click="distanceFun(16790.8224)">60 min</div>
                            <div :class="distanceType == '2' ? 'distance_legend_active':''" @click="distanceFun(25186.2336)">90 min</div>
                            <div :class="distanceType == '3' ? 'distance_legend_active':''" @click="distanceFun(50372.4672)">180 min</div>
                        </div>
                        <!-- <div id="googleMap" name="googleMap"></div> -->
                        <all-customer-tab ref="customerTab" v-if="changeIndex == '0'" :tableData="tableData2" :tableLength="tableLength" :num="0" :customerObj="customerObj" @scrollFun="scroll_fun" @showTable="showTableFun" @closeTable="closeTable"></all-customer-tab>
                        <all-supplier-tab ref="supplierTab" v-if="changeIndex == '1'" :tableData="tableData2" :tableLength="tableLength" :num="0" @scrollFun="scroll_fun" @closeTable="closeTable"></all-supplier-tab>
                        <all-takeaway-tab ref="takeawayTab" v-if="changeIndex == '2'" :tableData="tableTakeaway2" :tableLength="tableLength" :num="0" @scrollFun="scroll_fun" @typeFun="type_fun" @closeTable="closeTable" @tableSearch="tableSearch"></all-takeaway-tab>
                        
                    </div>
                    <!-- <div class="mainright"><img src="../../assets/images/right_border.png" alt=""></div> -->
                </div>
            </div>
            <business-left v-if="businessType == 'showAll'" @ParentType="ParentType"></business-left>
            
        </div>
        
        <div v-if="isShow">
            <map-total></map-total>
        </div>
        
        

    </div>
</template>
<script>
import {mapState,mapMutations} from 'vuex'
import {getStoreDetail,getStoremap,getStoreListSupplier} from "../../api/map"
import {getStoreList,getStoreListAll,getShopList,getWeeklyUsersCount,getWeeklySalesCount} from '../../api/business'
import mapTotal from "@/components/map/total.vue"
import allSupplierTab from "@/components/map/allSupplierTab.vue"
import allCustomerTab from "@/components/map/allCustomerTab.vue"
import allTakeawayTab from "@/components/map/allTakeawayTab.vue"
import { loadModules } from "esri-loader";
import businessLeft from "@/components/backgroundRank/businessLeft.vue";
import m1 from '../../assets/images/m6.png'
export default {
    data() {
        return {
            lat:"",
            long:"",
            isShow:false,
            isArcgis:true,
            loading:false,
            isGoogle:true,
            isMap:true,
            drawer:false,
            dialogLoading:false,
            tableData: [],
            tableData2: [],
            tableSupplier:[],
            tableTakeaway:[],
            tableTakeaway2:[],
            tableLength:0,
            mapView:null,
            infowindow:null,
            googletimer:null,
            markerStyles:[
            {
                url:m1,
                width:50,
                height:50,
                iconAnchor:[0,0],
                backgroundPosition:'0,0',
                anchor:[0,3]
            }],
            urlType:'',
            changeIndex:0,
            turnoverNum:0,//turnover总和
            businessType:'',
            customerObj:{
                existingCustomer:'',
                waitingCustomer:'',
                weeklySales:""
            },
            tableStatus:false,
            distanceNum:8395.4112,
            isGoogleMap:'',
            temporaryLat:'',//临时点击的
            temporaryLng:'',//临时点击的
            CircleAry:[],
            MarkerAry:[],
            postcodeNum:'',//搜索条件
            newTypeArr:[],
            distanceType:'0'
        }
    },
    components:{
        mapTotal,
        allSupplierTab,
        allCustomerTab,
        allTakeawayTab,
        businessLeft
    },
    computed:{
        ...mapState(['mapDetailType'])
    },
    watch:{
        "mapDetailType":{
            handler: function (newValue, oldValue) {
                this.isShow = newValue;
            },
            deep: true
        }
    },
    created(){
        this.urlType = window.location.hostname;
    },
    mounted() {
        this.createGoogle()
        // if(this.isArcgis) {
            // this.inintArcgis();
            this.getStoremap('')
        // }
    },
    methods:{
        ParentType(){
            this.isMap = true;
            this.businessType = '';
        },
        showTableFun(){
            this.isMap = false;
            this.businessType = 'showAll';
        },
        scroll_fun(scrollNum,refsName){
            let tabHeight = 0;
            for (let i = 0; i < this.tableData2.length; i++) {
                this.tableData2[i].top = tabHeight;
                // tabHeight = tabHeight + 66;
                tabHeight = tabHeight + document.getElementById('anchor'+this.tableData2[i].pageNum+this.tableData2[i].counts).offsetHeight;
                this.tableData2[i].bottom = tabHeight;
            }
            let scrollTop = scrollNum+(document.getElementById('anchor'+this.tableData2[0].pageNum+this.tableData2[0].counts).offsetHeight*3);
            for (let i = 0; i < this.tableData2.length; i++) {
                if (scrollTop > this.tableData2[i].top && scrollTop < this.tableData2[i].bottom) {
                    let tabCur = this.tableData2[i].pageNum;
                    if(refsName == '0'){
                        this.$refs.customerTab.supplierTabNum(tabCur);
                    }else if(refsName == '1'){
                        this.$refs.supplierTab.supplierTabNum(tabCur);
                    }else if(refsName == '2'){
                        this.$refs.takeawayTab.supplierTabNum(tabCur);
                    }
                    return false
                }
            }
        },
        createGoogle() {
            var scripts = document.querySelectorAll('script');
            scripts.forEach(item => {
                if(item.title == 'googlemap') {
                    this.isGoogle = false;
                }else {
                    this.isGoogle = true;
                }
            })
            if(this.isGoogle) {
                const oScript = document.createElement('script');
                oScript.type = 'text/javascript';
                oScript.title = 'googlemap';
                oScript.src = 'https://maps.googleapis.com/maps/api/js?&key=AIzaSyAmlmO-F6MbG3qvpUn41eiOP_jkIiJJhI4&sensor=true&v=beta';
                document.body.appendChild(oScript);
                this.isGoogle = false;
            }
        },
        distanceFun(num){
            if(num == 8395.4112){
                this.distanceType = '0';
            }else if(num == 16790.8224) {
                this.distanceType = '1';
            }else if(num == 25186.2336) {
                this.distanceType = '2';
            }else {
                this.distanceType = '3';
            }
            this.distanceNum = num;
            let that = this;
            
            if(that.CircleAry.length>0){
                for(let i= 0;i<that.CircleAry.length;i++){
                    that.CircleAry[i].setMap(null)
                    that.CircleAry.splice(i, 1);
                }
            }

            if(that.MarkerAry.length>0){
                for(let i= 0;i<that.MarkerAry.length;i++){
                    that.MarkerAry[i].setMap(null)
                    that.MarkerAry.splice(i, 1);
                }
            }
            let clickMarker = new google.maps.Marker({
                position: {lat:that.temporaryLat,lng:that.temporaryLng},
                map: that.isGoogleMap,
                icon:{
                    url: require("@/assets/images/center_icon.png"),
                    scaledSize: new google.maps.Size(15,20),
                }
            });
            that.MarkerAry.push(clickMarker);

            let cityCircle = new google.maps.Circle({
                strokeColor: "#465dfd",
                strokeOpacity: 0.5,
                strokeWeight: 2,
                fillColor: "#465dfd",
                fillOpacity: 0.12,
                map:that.isGoogleMap,
                center: {lat:that.temporaryLat,lng:that.temporaryLng},
                radius: that.distanceNum,
            });
            that.CircleAry.push(cityCircle);
        },
        initGoogle() {
            var that = this;
            that.loading = true;
            const mapOptions = {
                tilt: 0,
                heading: 0,
                zoom: this.tableData.length > 0 ? 10 : 5,
                center:{lat: this.tableData.length >0?this.tableData[0].lat*1:52.34291375, lng: this.tableData.length >0?this.tableData[0].lng*1:-0.80092331 },
                disableDefaultUI: true,
                mapTypeControl:true,
                // mapTypeControlOptions: {
                //     style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                //     mapTypeIds: ["roadmap", "satellite"],
                // },
                gestureHandling: 'greedy',//"none", //禁止缩放
                keyboardShortcuts: false,
                mapTypeId:"hybrid",
            }
            let dommap = document.getElementById('maps')
            let pObjs = document.getElementsByName('googleMap')
            if(pObjs.length > 0) {
                for(let x= 0;x<pObjs.length;x++) {
                    dommap.removeChild(pObjs[x])
                }
            }
            let googleMap = document.createElement('div');
            googleMap.setAttribute("id","googleMap")
            googleMap.style.width = '100%';
            googleMap.style.height = '100%';
            googleMap.setAttribute("name","googleMap")
            document.getElementById('maps').appendChild(googleMap)
            that.isGoogleMap = '';
            that.isGoogleMap = new google.maps.Map(googleMap,mapOptions);

            
            that.CircleAry = [];
            that.MarkerAry = [];
            if(this.changeIndex == '2'){
                that.isGoogleMap.addListener('mousedown', function(e) {
                    if(e.domEvent.button == 2) {
                        that.temporaryLat = e.latLng.lat();
                        that.temporaryLng = e.latLng.lng();

                        if(that.MarkerAry.length > 0){
                            for(let i= 0;i<that.MarkerAry.length;i++){
                                that.MarkerAry[i].setMap(null)
                                that.MarkerAry.splice(i, 1);
                            }
                        }

                        let clickMarker = new google.maps.Marker({
                            position: {lat:that.temporaryLat,lng:that.temporaryLng},
                            map: that.isGoogleMap,
                            icon:{
                                url: require("@/assets/images/center_icon.png"),
                                scaledSize: new google.maps.Size(15,20),
                            }
                        });
                        that.MarkerAry.push(clickMarker);

                        if(that.CircleAry.length  > 0){
                            for(let j= 0;j<that.CircleAry.length;j++){
                                that.CircleAry[j].setMap(null)
                                that.CircleAry.splice(j, 1);
                            }
                        }
                        
                        let cityCircle = new google.maps.Circle({
                            strokeColor: "#465dfd",
                            strokeOpacity: 0.5,
                            strokeWeight: 2,
                            fillColor: "#465dfd",
                            fillOpacity: 0.12,
                            map:that.isGoogleMap,
                            center: {lat:that.temporaryLat,lng:that.temporaryLng},
                            radius: that.distanceNum,
                        });
                        that.CircleAry.push(cityCircle)
                    }
                });
            }
            this.infowindow = new google.maps.InfoWindow()
            var markerList = []
            if(that.tableData.length > 0) {
                if(that.changeIndex == '2'){// All Takeaway
                    that.tableData.forEach(item => {
                        let srcUrl = '';
                        switch (item.type){
                            case 1:
                                srcUrl = require("@/assets/images/map_icon1.png");
                                break;
                            case 2:
                                srcUrl = require("@/assets/images/map_icon2.png");
                                break;
                            case 3:
                                srcUrl = require("@/assets/images/map_icon3.png");
                                break;
                            case 4:
                                srcUrl = require("@/assets/images/map_icon4.png");
                                break;
                            case 5:
                                srcUrl = require("@/assets/images/map_icon5.png");
                                break;
                            case 6:
                                srcUrl = require("@/assets/images/map_icon6.png");
                                break;
                            case 7:
                                srcUrl = require("@/assets/images/map_icon7.png");
                                break;
                            case 8:
                                srcUrl = require("@/assets/images/map_icon8.png");
                                break;
                            default:
                                srcUrl = require("@/assets/images/suppliers_icon.png");
                                break;
                        }
                        const marker = new google.maps.Marker({
                            position: new google.maps.LatLng(item.lat*1, item.lng*1), // { lat: item.lat*1, lng: item.lng*1 },
                            map:that.isGoogleMap,
                            title: item.name,
                            icon:{
                                url: srcUrl,
                                scaledSize: new google.maps.Size(30,35),
                            }
                        });

                        //点击
                        marker.addListener("click", () => {
                            that.showInfowindow(item,marker)
                        });
                        markerList.push(marker)
                    })
                }else{
                    this.tableData.forEach(item => {
                        let srcUrl = item.amount > 0 ? require("@/assets/images/adreesgreen.png") : require("@/assets/images/adree.png")
                        const marker = new google.maps.Marker({
                            position: new google.maps.LatLng(item.lat*1, item.lng*1), // { lat: item.lat*1, lng: item.lng*1 },
                            map:that.isGoogleMap,
                            title: item.name,
                            icon:{
                                url: srcUrl,
                                scaledSize: new google.maps.Size(30,35),
                            }
                        });

                        marker.addListener("click", () => {
                            that.showInfowindow(item,marker)
                        });
                        markerList.push(marker)
                    })
                }

                that.loading = false;
                //传递给标记群集程序的选项
                // const clusterOptions = {
                //     imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
                //     //gridSize: 30,
                //     zoomOnClick: true,//单击不放大
                //     // maxZoom: 10,
                // };
                //标记聚类
                // new MarkerClusterer(map, markerList, clusterOptions);
                new MarkerClusterer(that.isGoogleMap, markerList, {styles:this.markerStyles,zoomOnClick: true})
                //显示所有标记
                
                
                var newBoundary = new google.maps.LatLngBounds(); 
                for(let index in markerList){ 
                    var position = markerList[index].position; 
                    newBoundary.extend(position); 
                } 
                that.isGoogleMap.fitBounds(newBoundary);
                if(this.changeIndex == '1'){
                    this.$nextTick(()=>{
                        that.isGoogleMap.setCenter({lat: 55.04291375, lng: -10.00092331});
                    });
                }
            }
        },
        showInfowindow(item,marker) {
            const userCode = item.code;
            let that = this;
            if(item.amount > 0) {
                var contents = `<div style="padding:0.25rem 0.25rem;color:#fff;text-align:left;">`
                    if(item.name.split('£')[1]){
                        contents += `<div style="text-align:left;">SHOP NAME: ${item.name.split('£')[0]}</div>
                        <div style="text-align:left;">Turnover: £ ${item.name.split('£')[1]}</div>`
                    }else{
                        contents += `<div style="text-align:left;">SHOP NAME: ${item.name}</div>`
                    }
                    contents += `<div style="width:100%;height:0.25rem;line-height:0.25rem;display: flex;justify-content: space-around;margin-top: 8px;">
                        <a style="color:#fff;font-size:0.1875rem;" href="javascript:void(0)" id="jumpDetail" >
                            Detailed
                        </a>
                        <a style="color:#fff;font-size:0.1875rem;" href="https://earth.google.com/web/search/${item.storeName}/@${item.lat*1},${item.lng*1},100a,500d,35y,-0h,60t,0r/data=${item.kai?item.kai:'KAI'}" target="_blank">
                            Location
                        </a>
                    </div>
                </div>`;
            }else {
                var contents = `<div style="padding:0.25rem 0.25rem;color:#fff;text-align:left;">`
                    if(item.name.split('£')[1]){
                        contents += `<div style="text-align:left;">SHOP NAME: ${item.name.split('£')[0]}</div>
                        <div style="text-align:left;">Turnover: £ ${item.name.split('£')[1]}</div>`
                    }else{
                        contents += `<div style="text-align:left;">SHOP NAME: ${item.name}</div>`
                    }
                    contents += `<div style="width:100%;height:0.25rem;line-height:0.25rem;display: flex;justify-content: space-around;margin-top: 8px;">
                        <a style="color:#fff;font-size:0.1875rem;" href="https://earth.google.com/web/search/${item.storeName}/@${item.lat*1},${item.lng*1},100a,500d,35y,-0h,60t,0r/data=${item.kai?item.kai:'KAI'}" target="_blank">
                            Location
                        </a>
                    </div>
                </div>`;
            }
            // const infowindow = new google.maps.InfoWindow({
            //     content: contents,
            // });
            that.infowindow.setContent(contents);
            that.isGoogleMap.addListener(that.infowindow,"domready",function(){
                if(document.getElementById('jumpDetail')) {
                    var detail = document.getElementById('jumpDetail')
                    that.isGoogleMap.addDomListener(detail,"click",function(){
                        that.$store.commit('changeDetailType',true)
                        that.$store.commit('setuserCode',userCode)
                    });
                }
            })

            that.infowindow.close()
            that.infowindow.open({
                anchor: marker,
                map:that.isGoogleMap,
                shouldFocus: true,
            });
        },
        inintArcgis() {
            var that = this;
            that.loading = true;
            let dommap = document.getElementById('maps')
            let pObjs = document.getElementsByName('googleMap')
            if(pObjs.length > 0) {
                for(let x= 0;x<pObjs.length;x++) {
                    dommap.removeChild(pObjs[x])
                }
            }

            let googleMap = document.createElement('div');
            googleMap.setAttribute("id","googleMap")
            googleMap.setAttribute("name","googleMap")
            googleMap.style.width = '100%';
            googleMap.style.height = '100%';
            document.getElementById('maps').appendChild(googleMap)

            loadModules(["esri/WebMap","esri/views/MapView","esri/config","esri/core/watchUtils","dojo/domReady!"]).then(
                ([WebMap, MapView,esriConfig,watchUtils]) => {
                esriConfig.apiKey = "AAPK73b3c93a31ce4d7ca693387287e2b079bTw137q8RlF0T9tKs_cVsF-ZsPcC4rKqEg6kfpJowVqPC3rotX9n1mcSFjxAmXOG";
                const webmap = new WebMap({
                    portalItem: {
                        id: "9d082f73179748f891c4f90c1b6fc1f5"
                    }
                });
                const view = new MapView({
                    container: "googleMap",
                    center: [-1,54],
                    zoom: 5,
                    minZoom:5,
                    minScale:900000,
                    map: webmap,
                    popup: {
                        dockEnabled: true,
                        dockOptions: {
                        buttonEnabled: false,
                        breakpoint: false
                        }
                    }
                })
                this.mapView = view;
                that.loading = false;
                view.when(() => {
                    // this.createdLegend(0,0)
                    watchUtils.watch(view.popup.viewModel, "active", (graphic) => {
                        let selectedFeature = view.popup.selectedFeature;
                        if(selectedFeature !== null && view.popup.visible !== false && graphic) {
                            let cityId = selectedFeature.attributes.OBJECTID_1;
                            that.loading = true;
                            that.getStoremap(cityId)
                        }
                    });
                })
            })
        },
        backMap() {
            let data = {
                    type: false,
                    center:{}
                }
                this.$store.commit('changeMapdetail',data)
            // if(this.isArcgis) {
            //     let data = {
            //         type: false,
            //         center:{}
            //     }
            //     this.$store.commit('changeMapdetail',data)
            // }else {
            //     this.inintArcgis();
            //     this.isArcgis = true;
            // } 
        },
        jumpMapDetail() {
            let that = this;
            this.isArcgis = false;
            this.loading = true;
            this.drawer = false;
            this.$nextTick(() => {
                setTimeout(()=> {
                    that.initGoogle()
                },10)
            })
        },
        closeDialog() {
            this.drawer = false;
            this.mapView.goTo(
                            {
                                center: [-1,54],
                                zoom: 5,
                            },
                            {
                                duration: 2000
                            }
                        )
        },
        Rowclick(row){
            this.$store.commit('changeDetailType',true)
            this.$store.commit('setuserCode',row.code)
        },
        createdLegend(inputGrades,inputColors) {
            var div = document.createElement('div');
            div.setAttribute("id", "info");

            var defaultGrades = [0, 10, 20, 50, 100, 200, 500, 1000],
                defaultColors = ['#FFEDA0', '#FED976', '#FEB24C', '#FD8D3C', '#FC4E2A', '#E31A1C', '#BD0026', '#800026'],
                grades = inputGrades.length > 0 ? inputGrades : defaultGrades,
                colors = inputColors.length > 0 ? inputColors : defaultColors,
                from, to;
                for (var i = 0; i < grades.length; i++) {
                    from = grades[i];
                    to = grades[i + 1];
                    let  istr = document.createElement('i');
                    istr.style.background = getColor(from + 1);
                    istr.style.width= '18px';
                    istr.style.height= '18px';
                    istr.style.float= 'left';
                    istr.style.marginRight= '8px';
                    istr.style.opacity= 0.7;
                    let  spanstr = document.createElement('span');
                    spanstr.innerHTML = from + (to ? '&ndash;' + to : '+') + '<br/>'
                    div.appendChild(istr);
                    div.appendChild(spanstr);
                }
                
                document.getElementById("maps").appendChild(div);
                // 根据属性范围设置渲染颜色
                function getColor(d) {
                    return d > grades[6] ? colors[7] :
                        d > grades[5] ? colors[6] :
                            d > grades[4] ? colors[5] :
                                d > grades[3] ? colors[4] :
                                    d > grades[2] ? colors[3] :
                                        d > grades[1] ? colors[2] :
                                            d > grades[0] ? colors[1] :
                                                colors[0];
                }
        },
        getStoremap(cityId){
            var that = this;
            that.tableLength = 0;
            getWeeklySalesCount().then(res => {
                this.customerObj = {
                    existingCustomer: 239,//res.data.data.existingCustomer,
                    waitingCustomer: 290,//res.data.data.waitingCustomer,
                    weeklySales:245305.48//res.data.data.weeklySales
                }
            })
            getWeeklyUsersCount().then(res => {
                let result = res.data.data;
                let ary = [];
                result.map(item => {
                    var reg = RegExp(/Cosmo/);
                    if(!item.name.match(reg) && item.lng !=  '' && item.lat != ''){
                        ary.push(item);   
                    }
                })
                if(ary) {
                    // that.drawer = true;
                    that.tableData = ary;
                    that.tableData2 = this.deepCopy(ary);
                    let pages = 0;
                    for(let i = 0;i<ary.length;i++){
                        if(i%6 == 0){
                            pages++;
                        }
                        that.tableData2[i].pageNum = pages;
                        that.tableData2[i].counts = i%6;
                    }
                    that.tableLength = that.tableData2.length;
                    let countNum = that.tableData2[that.tableData2.length-1].counts+1;
                    for(let n = 0;n<6-countNum;n++){
                        that.tableData2.push({address:'',name:'',amount:'',pageNum:pages,counts:countNum+n});
                    }
                    that.tableStatus = true;
                    // that.mapView.goTo(
                    //                 {
                    //                     center: [result[0].lng*1,result[0].lat*1],
                    //                     zoom: 6,
                    //                 },
                    //                 {
                    //                     duration: 2000
                    //                 }
                    //             )
                }else {
                    that.tableData = []
                }
                that.googleLoad()
            })
            // getShopList({areaId:''}).then(res => {
            //     let result = res.data.data;
            //     let ary = [];
            //     let resultA = JSON.parse(JSON.stringify(result).replace(/longitude/g, 'lng').replace(/latitude/g, 'lat'))
            //     resultA.map(item => {
            //         var reg = RegExp(/Cosmo/);
            //         if(!item.name.match(reg) && item.lng !=  '' && item.lat != ''){
            //             ary.push(item);   
            //         }
            //     })
            //     if(ary) {
            //         that.tableData = ary;
            //     }else {
            //         that.tableData = []
            //     }
            //     that.googleLoad()
            // })
        },
        googleLoad() {
            let that = this;
            // clearInterval(this.googletimer)
            // this.googletimer = null;
            // this.googletimer = setInterval(() => {
            //     if(that.isGoogle == false) {
            //         clearInterval(that.googletimer)
            //         that.googletimer = null;
            //         that.$nextTick(() => {
            //             setTimeout(()=> {
            //                 that.initGoogle()
            //             },2000)
            //         })
            //     }else {
            //         that.createGoogle()
            //     }
            // },1000)
            setTimeout(() => {
                that.initGoogle()
            },0)
        },
        type_fun(item){
            this.newTypeArr = item.join(",");
        }, 
        tableSearch() {
            this.getStoremapAll();
        },
        //搜索
        searchFun(){
            if(this.postcodeNum != ''){
                this.getStoremapAll();
            }
        },
        getStoremapAll() {
            var that = this;
            that.tableLength = 0;
            getStoreListAll({type:that.newTypeArr,code:that.postcodeNum}).then(res => {
                let result = res.data.data;
                let ary = [];
                result.map(item => {
                    var reg = RegExp(/Cosmo/);
                    if(!item.name.match(reg) && item.lng !=  '' && item.lat != ''){
                        ary.push(item);   
                    }
                })
                if(ary) {
                    // that.drawer = true;
                    that.tableTakeaway = ary;
                    that.tableData = that.tableSupplier.concat(that.tableTakeaway);
                    that.tableTakeaway2 = this.deepCopy(ary);
                    let pages = 0;
                    for(let i = 0;i<ary.length;i++){
                        if(i%6 == 0){
                            pages++;
                        }
                        that.tableTakeaway2[i].pageNum = pages;
                        that.tableTakeaway2[i].counts = i%6;
                    }
                    that.tableLength = that.tableTakeaway2.length;
                    let countNum = that.tableTakeaway2[that.tableTakeaway2.length-1].counts+1;
                    for(let n = 0;n<6-countNum;n++){
                        that.tableTakeaway2.push({address:'',name:'',amount:'',pageNum:pages,counts:countNum+n});
                    }
                    if(this.postcodeNum == ''){
                        that.tableStatus = true;
                    }
                    setTimeout(() =>  {
                        that.googleLoad()
                    },10)
                    // that.mapView.goTo(
                    //                 {
                    //                     center: [result[0].lng*1,result[0].lat*1],
                    //                     zoom: 6,
                    //                 },
                    //                 {
                    //                     duration: 2000
                    //                 }
                    //             )
                }else {
                    that.tableData = [];
                    that.googleLoad()
                }
                
            })
        },
        getStoreListSupplier(is_continue){
            var that = this;
            that.tableLength = 0;
            getStoreListSupplier({areaId:''}).then(res => {
                let result = res.data.data;
                let ary = [];
                result.map(item => {
                    var reg = RegExp(/Cosmo/);
                    if(!item.name.match(reg) && item.lng !=  '' && item.lat != ''){
                        ary.push(item);
                    }
                })
                if(ary) {
                    that.tableData = ary;
                    that.tableData2 = this.deepCopy(ary);
                    let pages = 0;
                    for(let i = 0;i<ary.length;i++){
                        if(i%6 == 0){
                            pages++;
                        }
                        that.tableData2[i].pageNum = pages;
                        that.tableData2[i].counts = i%6;
                    }
                    that.tableLength = that.tableData2.length;
                    if(that.tableData2.length > 0){
                        let countNum = that.tableData2[that.tableData2.length-1].counts+1;
                        for(let n = 0;n<6-countNum;n++){
                            that.tableData2.push({address:'',name:'',amount:'',pageNum:pages,counts:countNum+n});
                        }
                    }
                    that.tableStatus = true;
                    // let ts = 0;
                    // ary.forEach(element => {
                    //     let n = element.name.split('£')[1];
                    //     ts += n.substring(0,n.length-1)*1;
                    // });
                    // that.turnoverNum = Math.floor(ts);
                    if(is_continue == 'isContinue'){
                        that.tableSupplier = ary;
                        this.getStoremapAll()
                    }else {
                        that.googleLoad()
                    }
                }else {
                    that.tableData = [];
                    that.googleLoad()
                }
            })
        },
        changeMap(index) {
            this.tableData =  [];
            this.tableData2 =  [];
            this.tableSupplier = [];
            this.tableTakeaway = [];
            this.tableTakeaway2 = [];
            this.distanceNum = 8395.4112;
            this.distanceType = '0';
            this.postcodeNum = '';
            this.loading = true;
            this.changeIndex = index;
            if(index == '0'){
                this.getStoremap('')
            }else if(index == '1'){
                this.getStoreListSupplier();
            }else if(index == '2'){
                this.$nextTick(()=>{
                    this.$refs.takeawayTab.isNameTab();
                });
                this.getStoreListSupplier('isContinue');
            }
        },
        deepCopy(arr) {
            const newArr = []
            for(let i in arr) {
                if (typeof arr[i] === 'object') {
                    newArr[i] = this.deepCopy(arr[i])
                } else {
                    newArr[i] = arr[i]
                }
            }
            return newArr;
        },
        showLeftTable() {
            if(this.changeIndex == '0') {
                this.$refs.customerTab.showTable()
            }else if(this.changeIndex == '1') {
                this.$refs.supplierTab.showTable()
            }else if(this.changeIndex == '2'){
                this.$refs.takeawayTab.showTable()
            }
            this.tableStatus = false;
        },
        closeTable(){
            this.tableStatus = true;
        }
    }
}
</script>
<style scoped>
.search_class{
    position: absolute;
    top: 0.18rem;
    left: 3.2rem;
    /* background-color: #ffffff; */
    height: 40px;
    line-height: 40px;
    width: 4rem;
    z-index: 1;
    display: flex;
    border-radius: 4px;
    
}
.search_input{
    width: 70%;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background-color: rgba(000, 000, 000, 0);
}
.search_button{

}
.distance_legend{
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    width: 0.8rem;
    text-align: center;
    background-color: #143778;
    z-index: 1;
}
.distance_legend div{
    height:0.6rem;
    line-height: 0.6rem;
    color: #ffffff;
    cursor: pointer;
}
.distance_legend div:hover{
    background-color: #05399b;
}
.distance_legend_active {
    background-color: #05399b;
}
.containers{
    width: 100%;
    height: 100%;
    padding: .5rem .925rem .375rem .925rem;
}
.title {
    display: flex;
    justify-content:space-between;
    align-items:center;
    font-size: .4rem;
    font-weight: 500;
    color: #fff;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.login {
    
}
/* .login img {
    width: .7375rem;
    height: .7375rem;
    vertical-align: middle;
} */
.back {
    width: .4625rem;
    height: .375rem;
}
.main {
    width: 100%;
    height: 10rem;
    margin-top: .375rem;
    background-color: rgba(0,0,0,0.6);
    /* background: url('../../assets/images/mapdetail_bg.svg') no-repeat;
    background-size: 100% 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: relative; */
    border: .025rem solid #48A2B3;
    border-radius: .25rem;
}
.mainleft {
    width: .375rem;
    height: 100%;
    /* position: absolute;
    left: -0.025rem; */
}
.mainright {
    width: .375rem;
    height: 100%;
    /* position: absolute;
    right: 0.025rem; */
}
.mainleft img , .mainright img{
    width: 100%;
    height: 100%;
}
#maps {
    width: 100%;
    height: 10rem;
    /* padding: .375rem .125rem; */
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    border-radius: .25rem;
}
#googleMap {
    width: 100%;
    height: 100%; 
    margin: 0 auto;
    overflow: hidden;
}

.show_table_btn {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background: #fff;
    color: #000;
    width: 80px;
    height: .6125rem;
    line-height: .6125rem;
    border-radius: .025rem;
    font-size: .225rem;
    cursor:pointer;
    z-index: 1;
}

.hidden_table {
    animation: hideenT 1.5s forwards ease;
}

.show_tablle {
    animation: showT 1s forwards ease;
}


@keyframes hideenT{
    from {opacity: 1;}
    to {opacity: 0;}
}

@-webkit-keyframes hideenT /*Safari and Chrome*/{
    from {opacity: 1;}
    to {opacity: 0;}
}

@keyframes showT{
    from {opacity: 0;}
    to {opacity: 1;}
}

@-webkit-keyframes showT /*Safari and Chrome*/{
    from {opacity: 0;}
    to {opacity: 1;}
}

/deep/ .gm-style .gm-style-iw-c {
    background: url('../../assets/images/title.png') no-repeat;
    background-size: 100% 100%;
    box-shadow: none;
}
/deep/ .gm-style .gm-style-iw-d {
    overflow:auto !important;
}
/deep/ .esri-ui-manual-container > .esri-component {
        display: none;
    }
/deep/ .esri-ui {
    display: none;
}
.custom_dialog {
    width: 28%;
    position: absolute;
    top:8%;
    right:2%;
    background: #071632;
    padding: .25rem .375rem .4375rem;
    border-radius: .25rem;
    border: .0625rem solid #fff;
}
.custom_line{
    width: 100%;
    height: .0125rem;
    background: #000;
    margin: .1875rem auto;
}

.table_btn {
    background: url('../../assets/images/table_btn.png') no-repeat;
    background-size: 100% 100%;
    font-weight: bold;
    color: #fff;
    font-size: .225rem;
    border: none;
}

/deep/ .el-table td.el-table__cell {
    border-bottom: 0;
    color: #fff;
    background-color: #071632;
}
/deep/ .el-table::before {
    width: 0;
}
/deep/ .el-table--enable-row-hover .el-table__body tr:hover>td{
    background-color: rgba(20, 55, 120, 0.9) !important;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0rem;
}
/deep/ .el-table--scrollable-y .el-table__body-wrapper {
    background: #071632;
}
/deep/ .el-table  .gutter {
    background: #071632;
}
/deep/ .el-table, .el-table__expanded-cell {
    background: #071632;
}
.detail_show {
    display: block;
}
.logo_img {
        width: 2.5rem;
        height: .875rem;
        vertical-align: middle;
        text-align: left;
        display: inline-block;
    }
    .select {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1000003;
        padding: 0.125rem;
    }
    .select_btn {
        background: #fff;
        color: #000;
        height: .6125rem;
        line-height: 0.6125rem;
        border-radius: .025rem;
        cursor: pointer;
    }
    .select_text:hover {
        background: rgb(235, 235, 235);
    }
    .select_text {
        display: inline-block;
        padding: 0 .25rem 0 .25rem;
        font-size: .225rem;
    }
    /deep/ .el-table__empty-block {
        background: #091c38;
    }
    /deep/ .el-table__body-wrapper {
        background: #091c38;
    }
    /deep/ .el-table__header-wrapper {
        background: #091c38;
    }
    /deep/ .el-pager li.btn-quicknext {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pager li.btn-quickprev{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-dropdown {
        color:#71CDF9 !important;
        font-size: 0.2375rem !important;
    }
    /deep/ .el-table::before {
        width: 0;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td{
        background-color: #235dc8 !important;
    }
    /deep/ .el-pagination button:disabled {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination button {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager .number{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager li.active{
        color: #409EFF !important;
    }
    /deep/ .el-date-editor .el-input__inner {
       background: rgba(250, 250, 250, 0.1);
        color: #fff;
        border: 0;
        text-align: center;
    }
</style>