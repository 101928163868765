import http from '@/utils/http'

//Disturbtion To Consumer-外卖店菜品单日销售额排名列表
export function getConsumerDaysales(query) {
    return http({
      url: '/api/board/dishes/getDaySalesAnalysisList', 
      method: 'get',
      params:query,
      headers:{
        Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
      }
    })
}
//Disturbtion To Consumer-外卖店菜品周销售额排名列表
export function getConsumerWeeksales(query) {
    return http({
      url: '/api/board/dishes/getWeekSalesAnalysisList', 
      method: 'get',
      params:query,
      headers:{
        Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
      }
    })
}

//Disturbtion To Consumer-外卖店单菜品周销售额排名列表
export function getWeeksales(query) {
    return http({
      url: '/api/board/dishes/getDishesFourWeekSales', 
      method: 'get',
      params:query,
      headers:{
        Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
      }
    })
}

//Disturbtion To Consumer-外卖店单菜品日销售额排名列表
export function getDaysales(query) {
    return http({
      url: '/api/board/dishes/getDishesSevenDaySales', 
      method: 'get',
      params:query,
      headers:{
        Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
      }
    })
}