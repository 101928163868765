<template>
    <div>
        <div class="container-main">
            <div class="container_bg">
                <div class="title">
                    <div class="login">
                        <img src="../../assets/images/logo.png" alt="" class="logo_img">
                        <!-- <span> Pacific Foods</span> -->
                    </div>
                    <div class="back" @click="backMap">
                        <img src="../../assets/images/quit.png" alt="">
                    </div>
                </div>
                <div class="amount">
                    <el-row>
                        <el-col :span="12">
                           <div>
                                <span style="font-size:0.35rem;color: #FFFFFF;">Total Sales
                                   <span class="amount_text">
                                        <countTo :startVal='0' :endVal='storeAmount.sumAll?storeAmount.sumAll:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                    </span>
                                </span>
                            </div>
                            <div class="bottom_line"></div>
                        </el-col>
                        <el-col :span="12">
                            <div>
                                <span style="font-size:0.35rem;color: #FFFFFF;">Weekly Sales
                                    <span class="amount_text">
                                        <countTo :startVal='0' :endVal='storeAmount.sum7Day?storeAmount.sum7Day:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                    </span>
                                </span>
                            </div>
                            <div class="bottom_line"></div>
                        </el-col>
                    </el-row>
                </div>
                <div class="main">
                    <div class="main-top1">
                        <el-col :span="24">
                            <div class="main_center">
                                <el-table
                                    :data="businessWeek"
                                    :header-cell-style="{background:'#143778',color:'#71CDF9',fontSize: '0.2375rem',border:'0px'}"
                                    :row-style="{height:'0.625rem'}"
                                    style="width: 100%;margin-top:0.1875rem;"
                                    @row-click="weekRowclick"
                                    :row-class-name="tableRowClassNameweek"
                                    size="mini">
                                    <template slot="empty">
                                        <span style="color: #969799;">No data</span>
                                    </template>
                                    <el-table-column
                                    prop="goodsNameEn"
                                    label="Name"
                                    show-overflow-tooltip
                                    align="left">
                                    </el-table-column>
                                    <el-table-column
                                    prop="amount"
                                    label="Amount"
                                    show-overflow-tooltip
                                    align="right">
                                    <template slot-scope="scope">
                                        <countTo :startVal='0' :endVal='scope.row.amount?scope.row.amount:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    prop="total"
                                    label="QTY"
                                    align="right"
                                    width="120">
                                    </el-table-column>
                                    <!-- <el-table-column
                                    prop="unit"
                                    label="Unit"
                                    align="right"
                                    width="80">
                                    </el-table-column> -->
                                </el-table>
                                <div class="block" v-if="businessWeeksize >= 5">
                                    <el-pagination
                                        layout="prev, pager, next"
                                        :pager-count="5"
                                        :page-size="weekLimit.limit"
                                        :current-page="weekLimit.page"
                                        @current-change="week_current_change"
                                        :total="businessWeeksize">
                                    </el-pagination>
                                </div>
                            </div>
                        </el-col>
                        <!-- <el-col :span="12" class="main-top_bg">
                            <div class="main_center">
                                <el-table
                                    :data="businessDay.data"
                                    :header-cell-style="{background:'#143778',color:'#71CDF9',fontSize: '0.2375rem',border:'0px'}"
                                    :row-style="{height:'0.5225rem'}"
                                    style="width: 100%;margin-top:0.1875rem;"
                                    @row-click="cpuRowclick"
                                    :row-class-name="tableRowClassNameday"
                                    size="mini">
                                    <template slot="empty">
                                        <span style="color: #969799;">No data</span>
                                    </template>
                                    <el-table-column
                                    prop="dishesName"
                                    label="Name"
                                    show-overflow-tooltip
                                    align="left">
                                    </el-table-column>
                                    <el-table-column
                                    prop="amount"
                                    label="Amount"
                                    show-overflow-tooltip
                                    align="right">
                                    <template slot-scope="scope">
                                        <countTo :startVal='0' :endVal='scope.row.amount?scope.row.amount:0' :duration='1000' separator="," :decimals="2" prefix="￡"></countTo>
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    prop="dishesPrice"
                                    label="QTY"
                                    align="right"
                                    width="120">
                                    </el-table-column>
                                </el-table>
                                <div class="block" v-if="businessDay.size >= 5">
                                    <el-pagination
                                        layout="prev, pager, next"
                                        :pager-count="5"
                                        :page-size="dayLimit.limit"
                                        :current-page="dayLimit.index"
                                        @current-change="current_change"
                                        :total="businessDay.size">
                                    </el-pagination>
                                </div>
                            </div>
                        </el-col> -->
                    </div>
                    <div class="main-bottom">
                        <el-col :span="24">
                            <div class="main-bottom_center">
                                <div id="cpuColumn"></div>
                            </div>
                        </el-col>
                        <!-- <el-col :span="12">
                            <div class="main-bottom_center">
                                <div id="line1"></div>
                            </div>
                        </el-col> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';

import {getGoodsWeek} from '../../api/cpu'
import {getStatistics,getStoreAmonut,goodsMonth} from '../../api/map/index'
import {getConsumerWeeksales,getDaysales} from "../../api/consumer"
import countTo from 'vue-count-to';
import {toThousands} from "../../utils/utils"
export default {
    components: { countTo },
    data() {
        return {
            businessWeek:[],
            businessWeeksize:0,
            businessDay:{
                data:[],
                size:0
            },
            weekLimit:{
                page: 1,
                limit:5,
                userCode:'',
            },
            dayLimit:{
                index: 1,
                limit:5,
                start:'',
                end:""
            },
            columnData:{},
            lineData:[],
            storeAmount:{
                sum7Day:"",
                sumAll:""
            },
            weekRowindex:0,
            dayRowindex:0,
            myChart:null
        }
    },
    mounted(){
        this.weekLimit.userCode = this.$store.state.userCode;
        this.initFunction()
        let chartDom = document.getElementById('cpuColumn');
        this.myChart = echarts.init(chartDom);
        window.addEventListener('resize',() => {this.resize()})
    },
    methods:{
        initFunction() {
            this.getStoreTotle()
            this.getStatistics()
            // this.dayLimit.end = new Date();
            // this.changeEnd1(this.dayLimit.end)
        },
        getStoreTotle() {
            let parmas = {
                userCode:this.$store.state.userCode
            }
            getStoreAmonut(parmas).then(res => {
                this.storeAmount.sum7Day = res.data.data.sum7Day;
                this.storeAmount.sumAll = res.data.data.sumAll;
            })
        },
        //business
        getStatistics() {
            getStatistics(this.weekLimit).then(res => {
                if(res.data.code == 200) {
                    this.businessWeek = res.data.rows;
                    this.businessWeeksize = res.data.total;
                    if(this.businessWeek.length>0) {
                        this.getGoodsWeek(this.businessWeek[0])
                    }else{
                        this.columnData = [];
                        this.$nextTick(()=> {
                            let chartDom = document.getElementById('cpuColumn');
                            chartDom.innerHTML = 'No Data';
                            chartDom.style.cssText = 'text-align:center; color: #fff; border: none;line-height: 250px;font-size:15px;';
                            chartDom.removeAttribute('_echarts_instance_');
                        })
                    }
                }
            })  
        },
        weekRowclick(item) {
            this.getGoodsWeek(item)
            this.weekRowindex = this.getArrayIndex(this.businessWeek, item);
        },
        getGoodsWeek(item) {
            goodsMonth({goodsCode: item.goodsCode}).then(res => {
                if(res.data.code == 200) {
                    this.columnData = [];
                    this.columnData = res.data.data;
                    this.inintColumn()
                }
            })
        },
        week_current_change(page){
            this.weekLimit.page = page;
            this.weekRowindex = 0;
            this.getStatistics()
        },
        
        //app
        // getConsumerWeeksales() {
        //     let parmas = {
        //         index: this.dayLimit.index,
        //         limit:this.dayLimit.limit,
        //         start: this.timeStr(this.dayLimit.start),
        //         end: this.timeStr(this.dayLimit.end)
        //     }
        //     getConsumerWeeksales(parmas).then(res => {
        //         if(res.data.code == 1){
        //             if(res.data.data.data && res.data.data.data.length > 0) {
        //                 this.businessDay.data = res.data.data.data;
        //                 this.businessDay.size = res.data.data.size*1;
        //                 this.getDaysales(this.businessDay.data[0])
        //             }else {
        //                 this.businessDay = {
        //                     data:[],
        //                     size:0
        //                 }
        //                 this.lineData = [];
        //                 this.$nextTick(()=> {
        //                     let chartDom = document.getElementById('line1');
        //                     chartDom.innerHTML = 'No Data';
        //                     chartDom.style.cssText = 'text-align:center; color: #fff; border: none;line-height: 250px;font-size:15px;';
        //                     chartDom.removeAttribute('_echarts_instance_');
        //                 })
        //             }
        //         }
        //     })
        // },
        // current_change(page) {
        //     this.dayLimit.index = page;
        //     this.dayRowindex = 0;
        //     this.getConsumerWeeksales()
        // },
        // getDaysales(item) {
        //     getDaysales({dishesId: item.dishesId}).then(res => {
        //         if(res.data.code == 1) {
        //             this.lineData = [];
        //             this.lineData = res.data.data;
        //             this.initLine1()
        //         }
        //     })
        // },
        // cpuRowclick(item) {
        //     this.getDaysales(item)
        //     this.dayRowindex = this.getArrayIndex(this.businessDay.data, item);
        // },


        initLine1() {
            let data = [this.lineData.onePrice,this.lineData.twoPrice,this.lineData.threePrice,this.lineData.fourPrice,this.lineData.fivePrice,this.lineData.sixPrice,this.lineData.sevenPrice]
            let xdata = [this.lineData.oneDay,this.lineData.twoDay,this.lineData.threeDay,this.lineData.fourDay,this.lineData.fiveDay,this.lineData.sixDay,this.lineData.sevenDay]
            var rageSum = 0
            data.map(item => {
                rageSum += item
            })
            let rage = Math.ceil(rageSum/7)
            let rageData = [rage,rage,rage,rage,rage,rage,rage]

            var chartFold = document.getElementById('line1');
            let myFold  = echarts.init(chartFold);

            myFold.showLoading({
                text: 'loading',
                color: '#409eff',
                textColor: '#fff',
                maskColor: 'rgba(0, 0, 0, 0.1)',
                zlevel: 0,
            });;
            setTimeout(() => {
                    // setOption前隐藏loading事件 
                    myFold.hideLoading();
                    myFold.setOption(option);
            },1000)
            var option;
            option = {
                title: {
                    text: 'Daily Sales',
                    textStyle:{
                        color: '#fff',
                        fontSize: 20
                    }
                    
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    },
                    textStyle:{
                        align:'left'
                    },
                    formatter: function(params) {
                        var result = ''
                        var dotHtml =  `<div><div style="text-align:center">${params[0].name}</div><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#e78801"></span>`
                            result = dotHtml +'Sales' + `<span style="margin-left:10px;">￡</span>` + toThousands(params[0].data) + "</div>"
                        return result
                    }
                },
                legend: {
                    top: '0%',
                    right: '5%',
                    itemWidth:10,
                    itemHeight:10,
                    itemStyle:{
                        borderWidth:0,
                    },
                    textStyle:{
                        color:'#fff',
                        fontSize:15
                    }
                },
                grid: {
                    top:'20%',
                    left: '0%',
                    right: '4%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff',
                        }
                    },
                    axisLabel:{
                        fontSize:"10px",
                        interval: 0
                    },
                    
                    data: xdata
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            type: [5, 10],
                            dashOffset: 5,
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:false
                    },
                },
                dataZoom:{
                    type:'inside'
                },
                series: [
                    {
                        data: data,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2, 
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#e78801' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#e78801', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"Actual",
                        symbolSize:8,
                        symbol:'circle',
                        // stack: 'Total',
                        // showSymbol: false,
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#e78801' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#e78801', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        color:{
                            type: 'radial',
                                x: 0.5,
                                y: 0.5,
                                r: 0.5,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#F59011' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#FDDB00', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                        },
                    },
                    {
                        data: rageData,
                        type: 'line',
                        smooth: true,
                        areaStyle:{
                            opacity:0.2, 
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#00F0FF' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#3D8FFF', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        name:"Average",
                        symbolSize:8,
                        symbol:'circle',
                        lineStyle:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#00F0FF' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#3D8FFF', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                            }
                        },
                        color:{
                            type: 'radial',
                                x: 0.5,
                                y: 0.5,
                                r: 0.5,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#3D8FFF' // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#00F0FF', // 0% 处的颜色,
                                    },
                                ],
                                global: false // 缺省为 false
                        },
                        // tooltip:{
                        //     show:false
                        // },
                        showSymbol:false
                    }
                ]
            };
            // option && myFold.setOption(option);
        },
        inintColumn() {
            let data = [];
            let xdata = [];
            this.columnData.map(item => {
                data.push(item.qty)
                xdata.push(item.dt)
            })
            this.myChart.showLoading({
                text: 'loading',
                color: '#409eff',
                textColor: '#fff',
                maskColor: 'rgba(0, 0, 0, 0.1)',
                zlevel: 0,
            });;
            setTimeout(() => {
                    // setOption前隐藏loading事件 
                   this. myChart.hideLoading();
                    this.myChart.setOption(option);
            },1000)
            let option;
            option = {
                title: {
                    text: "Monthly Amount",
                    textStyle:{
                        color: '#fff',
                        fontSize: 20
                    }
                    
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'none'
                    },
                    formatter: function(params) {
                        var result = ``
                        var dotHtml =  `<div><div style="text-align:center">${params[0].name}</div><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#28b1ff"></span>`
                            result = dotHtml +'Sales' + `<span style="margin-left:10px;">￡</span>` + toThousands(params[0].data) + "</div>"
                        return result
                    }
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '10%',
                    top:"15%",
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: xdata,
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine:{
                        show:true,
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                    splitLine:{
                        show:true,
                        lineStyle:{
                            color:'#FFFFFF',
                            opacity:'0.1'
                        },
                    },
                    axisLabel:{
                        formatter: function (value) {
                            let texts = '';
                            texts = toThousands(Math.floor(value/1000*1000));
                            return texts;
                        }
                    }
                },
                series: [
                    {
                        name:'Sales',
                        type: 'bar',
                        large:true,
                        barWidth : 30,
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: '#28b1ff' // 0% 处的颜色
                                },
                                {
                                    offset: 1,
                                    color: '#0072ff' // 0% 处的颜色
                                },
                                // {
                                //     offset: 0.9,
                                //     color: '#000' // 0% 处的颜色
                                // },
                                // {
                                //     offset: 1,
                                //     color: '#000' // 100% 处的颜色
                                // }
                            ],
                            global: false // 缺省为 false
                        },
                        data: data
                    }
                ]
                };

            // option && myChart.setOption(option);
        },
        //返回
        backMap() {
            this.$store.commit('changeDetailType',false)
        },
        // changeEnd1(time) {
        //     let t = new Date(time.getTime())
        //     const minTime = new Date(t).setMonth(new Date(t).getMonth() - 1);
        //     this.dayLimit.start = new Date(minTime)
        //     this.getConsumerWeeksales()
        // },
        timeStr(dataStr){
            var date = new Date(dataStr);
            var y = date.getFullYear();

            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;

            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;

            // var h = date.getHours();
            // h = h < 10 ? ('0' + h) : h;

            // //获得分
            // var mm = date.getMinutes()
            // mm = mm < 10 ? ('0' + mm) : mm;

            // //获得秒
            // var ss = date.getSeconds()
            // ss = ss < 10 ? ('0' + ss) : ss;

            // return y+'-'+m+'-'+d+' '+h+':'+mm+':'+ss
            return y+'-'+m+'-'+ d;
        },
        tableRowClassNameweek({row, rowIndex}) {
            if (rowIndex === this.weekRowindex) {
                return 'highlight-row'
            }
            return '';
        },
        tableRowClassNameday({row, rowIndex}) {
            if (rowIndex === this.dayRowindex) {
                return 'highlight-row'
            }
            return '';
        },
        getArrayIndex(arr, obj) {
            var i = arr.length;
            while (i--) {
                if (arr[i] === obj) {
                return i;
                }
            }
            return -1;
        },
        resize() {
            this.myChart.resize({height:'4.2rem'})
        }
    }
}
</script>
<style scoped>
    .containers {
        width: 100%;
        height: 100%;
        padding: .25rem 1.25rem;
        box-sizing: border-box;
    }
    .container-main {
        width: 100%;
        height: 100%;
        background: url('../../assets/images/analysis.png') no-repeat;
        background-size: 100% 100%;
    }
    .container_bg {
        width: 100%;
        height: 100%;
        padding: .375rem 1.5rem;
        box-sizing: border-box;
    }
    .title {
        display: flex;
        justify-content:space-between;
        align-items:center;
        font-size: .4rem;
        font-weight: 500;
        color: #fff;
    }
    /* .login img {
        width: .7375rem;
        height: .7375rem;
        vertical-align: middle;
    } */
    .back {
        width: .4625rem;
        height: .375rem;
    }
    .amount {
        margin-top: .25rem;
    }
    .amount_text {
        font-size:0.4625rem;
        font-weight: bold;
        color: #FFFFFF;
        background: linear-gradient(0deg, #fcc480 0%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .bottom_line{
        width: 3.25rem;
        height: .025rem;
        margin-top: .025rem;
        background: radial-gradient(ellipse,#00C8F1,#000);
        margin: .025rem auto;
    }
    .main {
        width: 100%;
        height:100%;
        margin-top: .125rem;
    }
    .main-top{
        height: 5.3875rem;
        width: 100%;
    }
    .main-top1 {
        height: 5.3875rem;
        width: 100%;
        background: url('../../assets/images/analysis_bottombg.png') no-repeat;
        background-size: 100% 100%;
    }
    .main-top_bg {
        width: 50%;
        height: 100%;
        background: url('../../assets/images/left_top.png') no-repeat;
        background-size: 100% 100%;
    }
    #line1 {
        height: 4.2rem;
    }
    #cpuColumn{
        height: 4.2rem;
    }
    .linetime {
        z-index: 10000;
        width: 50%;
        background: rgba(250, 250, 250, 0.1);
        border: none;
    }
    /deep/ .el-range-editor--mini .el-range-input {
        background: rgba(250, 250, 250, 0);
        color: #fff;
    }
    /deep/ .el-range-editor--mini .el-range-separator {
        color: #fff;
    }
    .main_center {
        padding: .5625rem 1.0625rem;
        text-align: center;
        margin: 0  auto;
        position: relative;
    }
    /deep/ .el-table td.el-table__cell {
        border-bottom: 0;
        color: #fff;
        background-color: #091c38;
    }
    /deep/ .el-table::before {
        width: 0;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td{
        background-color: #235dc8 !important;
    }
    /deep/ .el-pagination button:disabled {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination button {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager .number{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pagination .el-pager li.active{
        color: #409EFF !important;
    }
    /deep/ .el-date-editor .el-input__inner {
       background: rgba(250, 250, 250, 0.1);
        color: #fff;
        border: 0;
        text-align: center;
    }
    .main-bottom {
        height: 4.5375rem;
        width: 100%;
        background: url('../../assets/images/analysis_bottombg.png') no-repeat;
        background-size: 100% 100%;
    }
    .block {
        position: absolute;
        bottom: 0;
        right: 12%;
    }
    .main-bottom_center {
        padding: .375rem 1.0625rem;
        text-align: center;
        margin: 0  auto;
    }
    .linetime1 {
        z-index: 10000;
        width: 60%;
        border: none;
        margin: 0 auto;
        margin-bottom: .5625rem
    }
    /deep/ .linetime1 .el-date-editor.el-input{
        width: 100%;
    }
    /deep/ .el-table__empty-block {
        background: #091c38;
    }
    /deep/ .el-table__body-wrapper {
        background: #091c38;
    }
    /deep/ .el-table__header-wrapper {
        background: #091c38;
    }
    /deep/ .el-pager li.btn-quicknext {
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    /deep/ .el-pager li.btn-quickprev{
        color: #fff;
        background: rgba(0, 0, 0, 0);
    }
    .logo_img {
        width: 2.5rem;
        height: .875rem;
        vertical-align: middle;
        text-align: left;
        display: inline-block;
    }
</style>