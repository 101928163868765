import http from '@/utils/http'

//获取Disturbtion To Business(总销售、周销售、单日销售额)
export function getTotalSales() {
    return http({
      url: '/prod-api/app/statistics/businessStatistic',
      method: 'get',
    })
}

//Foodo2o 环境获取Disturbtion To Business(总销售、周销售、单日销售额)
export function getBusinessStatisticFoodo2o() {
  return http({
    url: '/prod-api/app/statistics/businessStatisticFoodo2o',
    method: 'get',
  })
}

//获取Disturbtion To Business(Business周销量排名（数量）)
export function getWeeklyrank(data) {
    return http({
      url: '/prod-api/app/statistics/businessSeven',
      method: 'post',
      data:data
    })
}

//获取Disturbtion To Business(Business日销量排名（数量）)
export function getDayrank(data) {
    return http({
      url: '/prod-api/app/statistics/businessDay',
      method: 'post',
      data:data
    })
}


//演示接口

//获取商品分类
export function getClassification(data) {
  return http({
    url: '/prod-api/app/statistics/goodsCategory',
    method: 'get'
  })
}


//查询所有菜品
export function getAllgoods(data) {
  return http({
    url: '/prod-api/app/statistics/allGoods',
    method: 'post',
    data:data
  })
}

// //查菜品sales数据

export function getGoodsLine(data) {
  return http({
    url: '/prod-api/app/statistics/goods6Monthly',
    method: 'post',
    data:data
  })
}

// export function getGoodsLine(data) {
//   return http({
//     url: '/prod-api/app/statistics/goodsCurrYearDays',
//     method: 'post',
//     data:data
//   })
// }


// 7个月数据
export function getGoodsColumn(data) {
  return http({
    url: '/prod-api/app/statistics/data7Month',
    method: 'post',
    data:data
  })
}

// 查所有store
export function getStoreList(query) {
  return http({
    url: '/api/board/data/getStoreList', 
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//查6个月数据
export function getGoodsMonth6(query) {
  return http({
    url: '/api/board/data/getStoreListByCode', 
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//商城销量排行

export function getSalesrank(query) {
  return http({
    url: '/api/board/data/getMallDishesList', 
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//商城销量排行(测试)

export function getSalesrankTest(query) {
  return http({
    url: '/api/board/data/getMallDishesListTest', 
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//商城销量排行salse

export function getSalesData(query) {
  return http({
    url: '/api/board/data/getMallDishesListForMonth', 
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//按时间段查菜品销售


export function getrangeGMV(data) {
  return http({
    url: '/prod-api/app/statistics/rangeGMV',
    method: 'post',
    data:data
  })
}

export function getStoreListAll(query) {
  return http({
    url: '/api/board/data/getStoreListAll', 
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//查店家最近6个月数据
export function recent6Month(data) {
  return http({
    url: '/prod-api/app/statistics/recent6Month',
    method: 'post',
    data:data
  })
}

//店铺列表-按购买金额排序
export function getCountList(data) {
  return http({
    url: '/prod-api/app/statistics/store/countList',
    method: 'post',
    data:data
  })
}

//获取商城全部店铺数据
export function getShopList() {
  return http({
    url: '/prod-api/app/statistics/shopList',
    method: 'get'
  })
}

//获取商城全部店铺数据
export function getShopAmountList() {
  return http({
    url: '/prod-api/app/statistics/shopAmountList',
    method: 'get'
  })
}

// 获取所用用户上一周销售额

export function getWeeklyUsersCount() {
  return http({
    url: '/prod-api/app/statistics/store/getWeeklyUsersCount',
    method: 'get'
  })
}
// 所用用户上一周销售额汇总

export function getWeeklySalesCount() {
  return http({
    url: '/prod-api/app/statistics/store/getWeeklySalesCount',
    method: 'get'
  })
}

// Cosmo数据
export function getCosmoSales(query) {
  return http({
    url: '/api/board/cosmo/getCosmoSales', 
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//cosmo 6个月数据
export function getCosmoSixMonth(query) {
  return http({
    url: '/api/board/cosmo/getCosmoSixMonth', 
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}