import axios from 'axios';
import { getToken } from '@/utils/auth'

const http = axios.create({
    baseURL: '',
    timeout: 200000
})

http.interceptors.request.use((config) => {
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
        if(getToken() != '29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np') {
            location.href = '#/login';
        }
        // config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }else {
        location.href = '#/login';
    }
    return config;
},function (error) {
    return Promise.reject(error)
})

http.interceptors.response.use((response)=>{
    if (response.status === 200) {
        return Promise.resolve(response);
    } else {
        return Promise.reject(response);
    }
},function(error) {
    return Promise.reject(error)
})
export default http;