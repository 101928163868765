import http from '@/utils/http'

//获取Disturbtion To Business(总销售、周销售、单日销售额)
export function getTotalSales() {
    return http({
      url: '/prod-api/app/statistics/cpuStatistic',
      method: 'get',
    })
}

//获取Disturbtion To Business(Business周销量排名（数量）)
export function getWeeklyrank(data) {
    return http({
      url: '/prod-api/app/statistics/cpuSeven',
      method: 'post',
      data:data
    })
}

//获取Disturbtion To Business(Business日销量排名（数量）)
export function getDayrank(data) {
    return http({
      url: '/prod-api/app/statistics/cpuDay',
      method: 'post',
      data:data
    })
}

//获取CPU or Business选中商品周排名
export function getGoodsWeek(data) {
    return http({
      url: '/prod-api/app/statistics/goodsWeek',
      method: 'post',
      data:data
    })
}

//获取CPU or Business选中商品单日7天排名
export function getGoodsDays(data) {
    return http({
      url: '/prod-api/app/statistics/goods7Days',
      method: 'post',
      data:data
    })
}

// 查cpu
export function getcpuList(query) {
  return http({
    url: '/api/board/data/getCpuList', 
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}


// 查cpu 详细
export function getcpuSales(query) {
  return http({
    url: '/api/board/data/getDateList', 
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//首页cpu
export function getcpuData(query) {
  return http({
    url: '/api/board/data/getCpuTop5', 
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//获取商城热加工按月份统计
export function getHotFoodsAnalysisList(query) {
  return http({
    url: '/api/board/shop/getHotFoodsAnalysisList', 
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//获取商城热加工销售
export function getHotFoodsList(query) {
  return http({
    url: '/api/board/shop/getHotFoodsList', 
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//获取CPU商城热加工销售
export function cpuHotGoodsRanking(data) {
  return http({
    url: '/prod-api/app/statistics/cpuHotGoodsRanking',
    method: 'post',
    data:data
  })
}

//获取CPU商城冷加工销售
export function cpuColdGoodsRanking(data) {
  return http({
    url: '/prod-api/app/statistics/cpuColdGoodsRanking',
    method: 'post',
    data:data
  })
}

//首页获取cpu 前5销售额
export function cpuRankList() {
  return http({
    url: '/prod-api/app/statistics/rankHotCold',
    method: 'get',
  })
}
