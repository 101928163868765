import http from '@/utils/http'

export function getSvg() {
  return http({
    url: 'json/UK.svg',
    method: 'get',
  })
}

export function getMap() {
  return http({
    // url: 'json/map.json',
    url:"json/store.json",
    method: 'get',
  })
}

export function getStoreDetail() {
  return http({
    url:"json/storedetail.json",
    method: 'get',
  })
}

export function getRank() {
  return http({
    url: '/prod-api/app/statistics/rank',
    method: 'get',
  })
}

export function getrankCPU() {
  return http({
    url: '/prod-api/app/statistics/rankCPU',
    method: 'get',
  })
}

export function getBusinessTotal() {
  return http({
    url: '/prod-api/app/statistics/total',
    method: 'get',
  })
}

// busnissuesr
export function getUserTotal() {
  return http({
    url: '/prod-api/app/statistics/user',
    method: 'get',
  })
}

//统计店铺订单数量
export function getStore() {
  return http({
    url: '/api/board/dishes/getDishesRank',
    method: 'get',
    headers:{
      token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//统计店铺销售额
export function getStoresales() {
  return http({
    url: '/api/board/order/getStoreOrderSalesRank',
    method: 'get',
    headers:{
      token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}
//app total
export function getStoreTotal() {
  return http({
    url: '/api/board/order/getStoreOrdersAndUsersTotal',
    method: 'get',
    headers:{
      token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//app user
export function getAppUserTotal() {
  return http({
    url: '/api/board/user/getUserStatistics',
    method: 'get',
    headers:{
      token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//首页右下角-6个月新增用户数
export function getSixMonthUsers(data) {
  return http({
    url: '/api/board/user/getSixMonthUsers?start=' + data.start + '&end=' + data.end,
    method: 'get',
    headers:{
      token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}


//成本利润统计
export function getCostStatistics() {
  return http({
    url: '/api/board/cost/getWeekCostStatistics',
    method: 'get',
    headers:{
      token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}


//地图
export function getStoremap(query) {
  return http({
    url: '/api/board/store/getStoreList',
    method: 'get',
    params:query,
    headers:{
      token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//GMV
export function getBusinessGmv(query) {
  return http({
    url: '/prod-api/app/statistics/data12Month',
    method: 'get',
    params:query
  })
}
//App
export function getAppGmv(query) {
  return http({
    url: '/api/board/order/getOneYearOrderAndUserAnalysis',
    method: 'get',
    params:query,
    headers:{
      token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//单店金钱统计
export function getStoreAmonut(data) {
  return http({
    url: '/prod-api/app/statistics/store/amountStatistics',
    method: 'post',
    data:data
  })
}
//店铺分析

export function getStatistics(data) {
  return http({
    url: '/prod-api/app/statistics/store/goodsQty',
    method: 'post',
    data:data
  })
}

export function goodsMonth(data) {
  return http({
    url: '/prod-api/app/statistics/store/goodsMonth',
    method: 'post',
    data:data
  })
}

export function getStoreListSupplier(query) {
  return http({
    url: '/api/board/data/getStoreListSupplier', 
    method: 'get',
    params:query,
    headers:{
      Token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}

//user二级页 10周数据
export function getTenweekUsers() {
  return http({
    url: '/api/board/user/getTenWeekUsers',
    method: 'get',
    headers:{
      token:'29ulIKXOqXfIPX4nnDffWCnUsNxaagQGCLy0Np'
    }
  })
}